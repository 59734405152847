// 主页及仪表板菜单中在添加组件时对位置的计算。
export default function (commonDataArrLayoutData) {
  // 克隆
  let commonDataArr = JSON.parse(JSON.stringify(commonDataArrLayoutData));
  //  如果没有组件，即初始化布局
  if (commonDataArr.length < 1) {
    commonDataArr.push({
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      minX: 0,
      maxY: 0,
    })
  }
  // 获取最大的y轴
  let maxY = Math.max(...commonDataArr.map((item) => item.y));
  // 获取最大的y轴的数组（中包含并行y轴组件）
  let maxYArr = commonDataArr.filter((item) => item.y === maxY);
  // 获取最大的y轴的数组中的最小的x轴
  let minX = Math.min(...maxYArr.map((item) => item.x));
  // 获取最大的y轴的x轴的高度
  // 根据最小的minx获取到它的当前确定组件的高度h
  let confirmH = maxYArr.find((item) => item.x === minX).h;
  return {
    maxY, // 最大的y轴
    maxYArr, // 最大的y轴数据
    minX, // 最大的x轴
    confirmH, // 最下面行的组件高度
  };
}
