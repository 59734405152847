
// 将仪表板和主页公用data及方法放置在一个文件中

import * as DashboardApi from "./api.js";
import getCpnLocation from "@/utils/getCpnLocation"; //获取当前底部组件的位置信息

export default {
  data() {
    return {
      isShowComponentsStoreAlert: false, //是否显示添加组件弹框
      isShowDashLinkageAlert: false, //是否显示联动弹框
      isShowPierceAlert: false, //是否显示穿透弹框
      isShowDeleteAlert: false, //是否显示删除图表弹框
      componentsDelTo: false, // 是否显示删除仪表板弹框
      isShowAddComponToKu: false, //是否显示添加到组件库弹框
      isShowComponentsEditorAlert: false, //是否显示编辑器弹框
      isShowCrateDashBoardAlert: false, //是否显示新建仪表板
      chartFullScreenTo: false, // 图表是否全屏显示
      DashboardDataFullScreen: {}, // 用于图表全屏的图表数据
      DashboardDatas: [], // 联动弹框中同一数据源的报表列表
      selectDashboardData: [], // 联动弹框中同一数据源的报表所选中的列表
      commonDataArrs: [],
      sourceType: "report",
      dashboardy: 0, // y轴的图表位置，默认是0
      chartId: "", // 图表id
      chartName: "", // 图表名称
      chartInfo: {}, //图表信息
      linkageArr: [], // 设置联动的所有图表
      reportLabel: '', // 图表名称
      throughType: '', // 穿透类型
      dashboardInfos: {
        id: "",
        name: "",
      }, // 仪表板信息
      homePageChartInfo: '', // 主页中直接添加图表后生成的图表信息
      titleType: '', // 对仪表板的重命名、转移文件夹
    };
  },
  methods: {
    // 获取仪表板组件布局
    async getChartsByDashboardId() {
      let params = {
        dashboardid: this.dashboardid,
      };
      let {
        data: { charts },
      } = await DashboardApi.getChartsByDashboardId(params);
      // 由于接口返回数据不是插件所需的x,y等key值名，所以需要自己修改名称
      charts.forEach((item) => {
        let MinWH = this.getMinWH(item.dashboardtype); // 获取图表对应的最小宽高
        item.x = Number(item.dashboardx);
        item.y = Number(item.dashboardy);
        item.h = Number(item.height);
        item.w = Number(item.width);
        item.minW = MinWH.minW;
        item.minH = MinWH.minH;
        item.i = item.id;
        item.type = "Dashboard";
      });
      this.commonDataArrs = charts;
      // 获取图表数据，判断是否可以继续添加图表
      if (charts && charts.length == 15) {
        this.$bus.$emit("sendComponentLength", false)
      } else {
        this.$bus.$emit("sendComponentLength", true)
      }
      // 每次调用仪表板组件布局接口之后获取到的commonDataArrs，根据该值计算y轴
      this.getDashboardy();
    },
    // 根据图表类型确定图表的最小宽高设置
    getMinWH(dashboardtype) {
      let minW = 3;
      let minH = 3;
      switch (dashboardtype) {
        case 'heatMap': // 热力图，4*4
          minW = 4;
          minH = 4;
          break;
        case 'number': // 数字图表，2*2
          minW = 2;
          minH = 2;
          break;
        case 'digital': // 同环比数字图表，4*3
          minW = 4;
          minH = 2;
          break;
        case 'bar_0': // 柱状图或者条形图，4*3
          minW = 4;
          minH = 3;
          break;
        case 'bar_1': // 柱状图或者条形图，4*3
          minW = 4;
          minH = 3;
          break;
        case 'bar_duidie': // 柱状图或者条形图，4*3
          minW = 4;
          minH = 3;
          break;
        case 'column_0': // 没有第二个分组的柱状图，4*
          minW = 4;
          minH = 3;
          break;
        case 'column_1': // 二维柱形图，4*4
          minW = 4;
          minH = 3;
          break;
        case 'column_duidie': // 6柱状堆叠图，4*4
          minW = 4;
          minH = 3;
          break;
        case 'line_0': // 没有第二个分组的折线图，4*4
          minW = 4;
          minH = 3;
          break;
        case 'line_1': // 有第二个分组的折线图，4*4
          minW = 4;
          minH = 3;
          break;
        case 'biaxial': // 同环比双轴图，4*4
          minW = 4;
          minH = 4;
          break;
        case 'waterfall': // 瀑布图*4
          minW = 4;
          minH = 3;
          break;
        case 'area': // 面积图*4
          minW = 4;
          minH = 3;
          break;
        case 'biaxialFigure': // 双轴图*4
          minW = 4;
          minH = 4;
          break;
        default: // 其他，3*3
          minW = 3;
          minH = 3;
          break;
      }
      return {
        minW: minW,
        minH: minH
      }
    },
    testCopy(val) {
      let res2 = JSON.parse(JSON.stringify(val));
      res2.x = res2.x + res2.w;
      res2.y = res2.y;
      res2.i = res2.i + Math.ceil(Math.random() * 10);
      if (res2.x + res2.w > 12) {
        res2.y = res2.y + res2.h;
        res2.x = 0;
      }
      let r = JSON.parse(JSON.stringify(this.commonDataArrs));
      r.push(res2);
      this.commonDataArrs = r;
    },
    // 添加组件
    addDashBoard() {
      this.isShowComponentsStoreAlert = true;
    },
    //从列表视图生成点击出现组件编辑器
    fnfromview() {
      this.sourceType = "listView";
      this.chartInfo = {};
      this.isShowComponentsEditorAlert = true;
    },
    // 从报表生成点击出现组件编辑器
    fnfromreport() {
      this.sourceType = "report";
      this.chartInfo = {};
      this.isShowComponentsEditorAlert = true;
    },
    //更多操作选择编辑
    fnchooseEdit(info, homePageChartInfo) {
      this.isShowComponentsEditorAlert = true;
      this.chartInfo = info;
      if (homePageChartInfo) {
        this.homePageChartInfo = homePageChartInfo;
      } else {
        this.homePageChartInfo = {};
      }
      if (info.reportid) { // 有报表id，属于报表数据源
        this.sourceType = "report";
      } else { // 否则属于列表视图数据源
        this.sourceType = "listView";
      }
    },
    //组件编辑器取消按钮
    componentEditorCancel() {
      this.isShowComponentsEditorAlert = false;
    },
    // 获取下次添加图表的y轴位置
    getDashboardy() {
      let cpnLocation = getCpnLocation(this.commonDataArrs);
      this.dashboardy = cpnLocation.maxY + cpnLocation.confirmH;
    },
    //组件编辑前确认按钮
    async componentEditorAffirm(isAdd, e, chartName) {
      this.isShowComponentsEditorAlert = false;
      // 判断添加图表的位置：主页、仪表板
      if (this.isAddToHomePage) {
        if (isAdd) {
          let val = {
            DashboardChooseID: "", // 仪表板id
            DashboardassemblyID: e.id, // 图表id
            name: chartName,
            type: 'homepageChart', // 表示是主页中直接添加图表组件
            DashboardassemblyType: e.DashboardassemblyType, // 图表类型
          }
          this.saveDashboardCpn(val); // 主页中保存组件
        } else {
          let val = {
            i: this.homePageChartInfo.i, // 主页中生成的图表id
            DashboardChooseID: "", // 仪表板id
            DashboardassemblyID: e.id, // 图表id
            name: chartName,
            type: 'homepageChart', // 表示是主页中直接添加图表组件
            DashboardassemblyType: e.DashboardassemblyType, // 图表类型
            h: this.homePageChartInfo.h,
            w: this.homePageChartInfo.w,
            x: this.homePageChartInfo.x,
            y: this.homePageChartInfo.y,
            // minW: this.homePageChartInfo.minW,
            // minH: this.homePageChartInfo.minH,
          }
          this.assemblyDashboardEditSave(val); // 主页中保存组件
        }
      } else {
        // 在仪表板中添加
        await this.getChartsByDashboardId();
        if (isAdd) {
          if (this.$refs.DashboardIndexLeftMenuRef) {
            // 图表添加成功之后需要更新所在仪表板宽高
            let ruleForm = this.$refs.DashboardIndexLeftMenuRef.dashboardList.find((item) => item.id ===
              this.$refs.DashboardIndexLeftMenuRef.chooseId);
            let params = {
              id: ruleForm.id,
              name: ruleForm.name,
              description: ruleForm.description,
              dashboardfolderid: ruleForm.dashboardfolderid,
              islightning: true,
              width: 12, // 仪表板的高度宽度固定为12
              height: this.dashboardy, // 仪表板的高度即为下次添加图表时的y轴
            };
            await DashboardApi.updateDashboard(params);
            this.$bus.$emit('scrollPositionBus'); // 添加完图表且更新完仪表板之后在主页和仪表板中定位到图表位置
          }
        }
        // 触发该图表信息更新
        this.$bus.$emit("getLinkagegetData", e);
      }
    },
    // 添加组件确认
    addCpmAffirm() {
      this.isShowComponentsStoreAlert = false;
    },
    // 筛选设置弹框
    setScreenCondition() {
      this.isShowRemittanceListAlert = true;
    },
    // 筛选设置取消按钮
    screenCancel() {
      this.isShowRemittanceListAlert = false;
    },
    // 筛选设置应用按钮
    async screenApplication(params) {
      let data = await DashboardApi.addDashboardCondition(params);
      if (data.result) {
        this.$message.success(this.$i18n.t("2"));
      }
      this.isShowRemittanceListAlert = false;
      this.$bus.$emit('startGetCondition');
    },
    // 添加组件取消
    addCpmCancel() {
      this.isShowComponentsStoreAlert = false;
    },
    //显示联动弹框
    fnchooseLinkage(value, infos) {
      this.DashboardDatas = value;
      this.chartInfo = infos;
      this.selectDashboardData = infos.linkageid ? infos.linkageid?.split(',') : [];
      this.isShowDashLinkageAlert = true;
    },
    //保存显示联动弹框
    async linkageAffirm(value) {
      this.linkageArr = value;
      this.chartInfo.linkageid = this.linkageArr.join(',');
      let data = await DashboardApi.updateChart(this.chartInfo);
      if (data.result) {
        // 设置联动成功！
        this.$message.success(this.$i18n.t("vue.dashboard.linkage.is.set.successfully"));
        this.getChartsByDashboardId();
      }
      this.isShowDashLinkageAlert = false;
      // 触发该图表信息更新
      let e = {
        id: this.chartInfo.id,
      }
      this.$bus.$emit("getLinkagegetData", e);
    },
    //取消显示联动弹框
    linkageCancel() {
      this.isShowDashLinkageAlert = false;
    },
    // 显示穿透弹框
    fnchoosepierce(chartInfo, through) {
      this.chartInfo = chartInfo;
      this.reportLabel = through.throughLabel;
      this.throughType = through.throughType;
      this.isShowPierceAlert = true;
    },
    // 设置穿透
    async pierceAffirm() {
      this.chartInfo.isurl = '1';
      let data = await DashboardApi.updateChart(this.chartInfo);
      if (data.result) {
        // 设置穿透成功
        this.$message.success(this.$i18n.t("vue.dashboard.setPenetrationSuccessful"));
        this.getChartsByDashboardId();
      }
      this.isShowPierceAlert = false;
      // 触发该图表信息更新
      let e = {
        id: this.chartInfo.id,
      }
      this.$bus.$emit("getLinkagegetData", e);
    },
    // 取消显示穿透弹框
    pierceCancel() {
      this.isShowPierceAlert = false;
    },

    addComponToKuOpen() {
      this.isShowAddComponToKu = true;
    },
    addAffirm() {
      this.isShowAddComponToKu = false;
    },
    addCancel() {
      this.isShowAddComponToKu = false;
    },
    // 显示新建仪表板弹框
    createDashBoard() {
      this.dashboardInfos = {
        id: "",
        name: "",
      };
      this.isShowCrateDashBoardAlert = true;
    },
    //取消新建仪表板弹框
    createDashBoardCancel() {
      this.isShowCrateDashBoardAlert = false;
    },
    //'删除仪表盘'弹框打开
    delDashboard() {
      this.componentsDelTo = true;
    },
    //点击取消'删除仪表盘'弹框消失
    componentsDelCancle() {
      this.componentsDelTo = false;
    },
    //删除仪表板
    async ComponentsDelConfirm() {
      let params = {
        id: this.dashboardid,
      };
      let data = await DashboardApi.deleteDashboard(params);
      this.componentsDelCancle();
      this.$message.success("删除仪表板成功！");
      this.$router.push({
        path: '/dashboardObject/dashBoardList'
      })
    },
    // 图表全屏关闭弹框
    chartFullScreenClose() {
      this.chartFullScreenTo = false;
    }
  },
};