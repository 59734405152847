// 仪表板接口
import axios from '@/config/httpConfig'
// 获取仪表板文件夹信息
export function getDashboardFolder(data) {
    return axios.post('/dashboard/getDashboardFolder', data)
}
// 保存仪表板文件夹
export function saveDashboardFolder(data) {
    return axios.post('/dashboard/saveDashboardFolder', data)
}
// 添加仪表板的最近查看接口
export function addDashboardRecentItem(data) {
    return axios.post('/dashboard/addDashboardRecentItem', data)
}
//仪表板文件夹列表
export function getDashboardFolderList(data) {
    return axios.post("/dashboard/getDashboardFolderList", data);
}
// 获取报表详细信息
export function getReportDataByDetail(data) {
    return axios.post('/report/getReportDataByDetail', data)
}
//重命名仪表板
export function updateDashboard(data) {
    return axios.post('/dashboard/updateDashboard', data)
}
//添加仪表板
export function addDashboard(data) {
    return axios.post('/dashboard/addDashboard', data)
}
//置顶仪表板文件夹
export function setTopFolder(data) {
    return axios.post("/dashboard/topFolder", data);
}
// 获取所仪表板列表
export function getDashboardList(data) {
    return axios.post('/dashboard/getDashboardList', data)
}
//删除仪表板
export function deleteDashboard(data) {
    return axios.post('/dashboard/deleteDashboard', data)
}
// 删除仪表板文件夹
export function deleteDashboardFolder(data) {
    return axios.post('/dashboard/deleteDashboardFolder', data)
}
// 组件模板中获取所有报表
export function getReportList() {
    return axios.post('/chart/getReportList')
}
// 组件模板中根据选择的报表id查询分组和统计值
export function getReport(data) {
    return axios.post('/chart/getReport', data)
}
// 组件模板中获取所有对象列表
export function getObjectList(data) {
    return axios.post('/object/getObjectList', data)
}
// 组件模板中根据选择对象获取所有视图列表
export function getViewList(data) {
    return axios.post('/view/list/getViewList', data)
}
// 组件模板中根据选择对象获取所有字段
export function getFieldsByObjIdForChart(data) {
    return axios.post('/chart/getFieldsByObjIdForChart', data)
}
// 仪表盘动态展示图表
export function getChartDynamicDisplay(data) {
    return axios.post('/chart/getChartDynamicDisplay', data)
}
// 编辑图表
export function updateChart(data) {
    return axios.post('/chart/updateChart', data)
}
// 添加图表
export function addChart(data) {
    return axios.post('/chart/addChart', data)
}
// 保存仪表板布局
export function updateChartsLocation(data) {
    return axios.post('/chart/updateChartsLocation', data)
}
// 删除图表
export function deleteChart(data) {
    return axios.post('/chart/deleteChart', data)
}